.contact-page {
  .contact-wrapper {
    padding-top: 25px;

    @media (min-width: 992px) {
      padding-top: 105px;
    }

    h2 {
      padding: 15px 0;
    }

    button {
      margin-bottom: 5px;
    }
  }
}