.ambasador-module {
  background: linear-gradient(269.92deg, #002b6e 6.09%, #124390 66.76%);
  color: white;
  margin-top: 100px;

  .image {
    margin-top: -80px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4ba5f8;
  }

  h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 25px;
  }

  .text {
    background-color: #ffffff1a;
    padding: 25px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 140px;
    .image {
      margin-bottom: -140px;
    }
  }

  @media (min-width: 1200px) {
    margin-bottom: 190px;
    .image {
      margin-bottom: -190px;
    }
  }
}
