.footer-cart-toolbar-module {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 5px;

  .price-wrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    .price {
      color: #303030;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .action-buttons {
    display: flex;
    justify-content: space-around;
  }

  @media (min-width: 576px) {
    bottom: 0;
    width: calc(100% - 60px);
    left: 60px;
    display: flex;
    padding: 10px 30px;
    justify-content: space-between;

    .price-wrapper {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
