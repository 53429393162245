@import "src/settings.scss";

.edit-panel-content {
  button {
    &.round {
      background: #053683;
      width: 250px;
      margin: 5px 0 0 0;
    }
  }
  .button {
    padding-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    button {
      &.round {
        width: inherit;
      }
    }
  }
  .connect-apps {
    padding-bottom: 20px;
    .social-media {
      display: flex;
      flex-direction: column;
      @media (min-width: 576px) {
        flex-direction: row;
      }
      .social-media-single {
        display: flex;
        align-items: center;
        padding: 7px 25px;
        margin: 5px 25px;
        border: 1px solid #DBDBDB;
        box-shadow: 0px 0px 4px 0px #00000026;
        width: 277px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 35px;
          height: 35px;
          object-fit: cover;
          margin-right: 15px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          margin: 0;
        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .form-wrapper {
      display: flex;
      flex-direction: column;
      .form {
        padding: 20px 0;
        .form-group {
          display: flex;
          flex-direction: column;
          padding-bottom: 5px;
    
          label {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0em;
            color: #303030;
    
          }
    
          input {
            padding: 5px 10px;
            border: 1px solid #CED4DA;
          }
        }


      }
      @media (min-width: 768px) {
        justify-content: space-between;
        flex-direction: row;
  
        .form {
          width: 45%;
        }
  
        .password {
          width: 45%;
        }
      }
  
    }
   
    .password {
      width: 100%;
      text-align: left;
      padding-bottom: 15px;

      h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #303030;
      }
    }
  }

  .notifications {
    .notifications-checkbox {
      padding-bottom: 65px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      input[type="checkbox"] {
        appearance: none;
        height: 25px;
        width: 25px;
        border: 1px solid #a9a9a9;
        margin-right: 15px;

        &:checked {
          background-image: url("../../../../public/check.png");
        }
      }
    }
  }
}