@import "src/settings.scss";
.gallery-module {
  width: 100%;
  margin-bottom: 50px;

  .gallery-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .image-box-module {
      margin: 10px;
      width: 300px;
      // height: 300px;

      @media (max-width: 2300px) {
        width: calc(16.666% - 20px);
        // height: 250px;
      }

      @media (max-width: 1700px) {
        width: calc(20% - 20px);
        // height: 250px;
      }

      @media (max-width: 1400px) {
        width: calc(25% - 20px);
        // height: 250px;
      }

      @media (max-width: 1150px) {
        width: calc(33.333% - 20px);
        // height: 250px;
      }

      @media (max-width: 870px) {
        width: calc(50% - 20px);
        // height: 250px;
      }

      @media (max-width: 576px) {
        width: calc(100% - 20px);
        // height: 250px;
      }
    }
  }
}
