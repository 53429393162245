@import "settings.scss";

button,
.link-button {
  border: none;
  color: white;
  font-weight: bold;
  padding: 4px 15px;
  margin: 0 4px;
  display: inline-block;
  text-decoration: none;

  &.normal {
    background-color: $color-primary;
  }

  &.fresh {
    background-color: transparent;

    &.active {
      background-color: inherit;
    }
  }

  &.grayround {
    border-radius: 30px;
    background: #464646;
  }

  &.borderround {
    border-radius: 30px;
    border: 1px solid #464646;
    color: #464646;
    font-weight: 500;
  }

  &.round {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    padding: 3px 25px;

    &.active-btn {
      background-color: #ffffffb2;
    }
  }
}
