@import "src/settings.scss";

.side-menu-module {
  position: fixed;
  bottom: 0;
  background: $primary-gradient;
  width: 100%;
  color: $color-white;
  height: 60px;
  padding: 10px;
  z-index: 1;
  .nav-item {
    width: 20%;
    text-align: center;
    margin: auto;
    text-decoration: none;
    font-size: 13px;
    color: $color-white;
    cursor: pointer;
    i {
      width: 100%;
      font-size: 26px;
      text-align: center;
      display: block;
    }
  }

  .basic {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .more {
    position: absolute;
    display: flex;
    padding: 10px;
    width: 100%;
    margin-right: -100%;
    bottom: 100%;
    right: 0;
    width: 100%;
    transition-duration: 0.5s;
    background: $color-white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1411764706);
    border-radius: 20px;

    &.show {
      margin-right: 0;
    }

    .nav-item {
      display: block;
      color: $color-blue;
    }
  }

  @media (min-width: 576px) {
    width: 60px;
    overflow-x: hidden;
    overflow-y: auto;
    top:0;
    left: 0;
    height: 100vh;
    padding: 0;
    background: white;
    transition-duration: 0.5s;
    padding-top: 60px;
    z-index: 1;
    box-shadow: 0px 4px 4px 0px #00000040;


    p {
      display: none;
      padding-left: 15px;
    }

    &:hover {
      width: 200px;

      .nav-item {
        width: 190px;

        p {
          display: inline;
          margin: 0;
        }
      }
    }

    .more-button {
      display: none;
    }
    .basic {
      flex-direction: column;
    }
    
    .nav-item {
      padding: 15px 0;
      text-align: left;
      width: 50px;
      margin-top: 10px;
      color: $color-primary;
      border-radius: 7px;
      margin: 5px;

      &:hover {
        background: lighten($color-primary, 16%);
        color: white;
      }

      &.active {
        background: $color-primary;
        color: white;
      }

      i {
        font-size: 20px;
        display: inline;
        width: 50px;
        margin-left: 15px;
      }
    }
    
    .more {
      background: transparent;
      position: static;
      display: inline;
      padding: 0;
      width: auto;
      margin-right: 0;
      bottom: auto;
      right: auto;
      .nav-item {
        color: $color-white;
      }
    }
  }
}
