.search-component {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 100%;
    height: 57px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 2px solid #dadada;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .search-icon {
    width: 25px;
    height: 26.527px;
    flex-shrink: 0;
    transform: translateX(-150%);
  }
}
