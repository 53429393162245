.image-box-module {
  .image-box-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    .info-bar {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background: #878787;
      height: 80px;
      margin-bottom: -100px;
      transition-duration: 0.5s;
      align-items: flex-start;
      justify-content: space-between;
      &.allways-show {
        margin-bottom: 0;
      }

      .info-text {
        text-align: left;
        color: #fff;
        width: calc(70% - 20px);
        margin: 20px 10px;
        h4 {
          font-weight: 600;
          font-size: 14px;
          white-space: nowrap; /* Zapobiega przenoszeniu tekstu do nowej linii */
          overflow: hidden; /* Ukrywa tekst, który nie mieści się w kontenerze */
          text-overflow: ellipsis;
          width: 100%;
          margin-bottom: 4px;
        }
        h5 {
          font-weight: 400;
          font-size: 14px;
          white-space: nowrap; /* Zapobiega przenoszeniu tekstu do nowej linii */
          overflow: hidden; /* Ukrywa tekst, który nie mieści się w kontenerze */
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      .action-button {
        padding: 3px 10px;
        border-radius: 50px;
        background: #d9d9d9;
        color: #878787;
        margin: 15px 15px 0 0;
        font-weight: 500;
        cursor: pointer;
      }
    }
    &:hover {
      .info-bar {
        margin-bottom: 0px;
      }
      .full-hover {
        opacity: 1;
      }
    }
    .full-hover {
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.85);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .full-hover-container {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 70px);
        height: 200px;
        align-content: center;
        justify-content: center;

        h4 {
          max-height: 60px; // Ustaw maksymalną wysokość na 2 linie
          overflow: hidden; // Ukryj nadmiarowy tekst
          text-overflow: ellipsis; // Dodaj "..." na końcu przyciętego tekstu
          display: -webkit-box; // Użyj prefiksu dla starszych przeglądarek
          -webkit-line-clamp: 2; // Ogranicz do 2 linii
          -webkit-box-orient: vertical; // Wertykalna orientacja
          margin-bottom: 16px;
        }
        h5 {
          font-weight: 400;
          font-size: 20px;
          white-space: nowrap; /* Zapobiega przenoszeniu tekstu do nowej linii */
          overflow: hidden; /* Ukrywa tekst, który nie mieści się w kontenerze */
          text-overflow: ellipsis;
          width: 100%;
        }
        .action-button {
          margin-top: 25px;
          border-radius: 15px;
          background: #444;
          width: 82px;
          height: 28px;
          flex-shrink: 0;
          cursor: pointer;

          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        }

        .favourite-button {
          position: absolute;
          right: 20px;
          top: 10px;
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
  }

  .inner-left-top {
    position: absolute;
    left: 10px;
    top: 10px;
  }

  .inner-right-top {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .out-bottom-render {
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
