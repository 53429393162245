@import "src/settings.scss";

.single-photo-module {
  padding: 0 25px 45px 25px;

  button {
    color: black;
  }

  .wrapper {
    .returner {
      width: 50px;
      padding: 35px 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .photo-wrapper {
      display: flex;
      justify-content: center;

      .arrow-back,
      .arrow-next {
        display: flex;
        justify-content: center;
        width: 5%;
        font-size: 26px;
        transition: all 0.3s;
      }

      .arrow-next:hover {
        padding-left: 10px;
      }

      .arrow-back:hover {
        padding-right: 10px;
      }

      .photo {
        position: relative;
        width: 280px;
        max-height: 600px;
        box-shadow: 0px 1px 20px 0px #00000040;

        span {
          position: absolute;
          right: 30px;
          top: 20px;
          font-size: 25px;
          color: #aeaeae;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .content-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;

      .header-wrapper {
        width: 90%;
        display: flex;
        padding: 25px 0;
        flex-direction: column-reverse;
        justify-content: flex-start;

        .header-text {
          h1 {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            letter-spacing: 0em;
            text-align: left;
          }

          .header-author {
            display: flex;
            justify-content: space-between;

            span {
              color: $color-grey;
              font-size: 18px;
              font-weight: 600;
              line-height: 22px;
              letter-spacing: 0em;
              text-align: left;
              padding-right: 15px;
            }
          }
        }

        .header-btns {
          display: flex;
          padding-bottom: 15px;
          button {
            color: white;
            max-height: 55px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .text-wrapper {
        display: block;
        width: 90%;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .single-photo-module {
    .wrapper {
      .content-wrapper {
        display: flex;
        .header-wrapper {
          flex-direction: row;
          justify-content: space-between;

          .header-btns {
            padding-bottom: 15px;
          }
        }
      }
      .photo-wrapper {
        .photo {
          width: 470px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
