.header-module {
  background: url("../../../public/header_background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  position: relative;
  z-index: 2;

  a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
  }

  .logo {
    text-align: left;
    a {
      padding: 10px;
    }
  }

  .mobile-menu {
    .search-component {
      margin: 20px 50px;
    }
    .burger-menu {
      position: absolute;
      right: 10px;
      top: 0;
      font-size: 30px;
      transition-duration: 0.5s;
    }

    .menu {
      position: fixed;
      width: 100vw;
      left: 1000px;
      top: 50px;
      z-index: 10;
      height: calc(100vh - 50px);
      background: #001e4d;
      overflow-y: auto;

      transition-duration: 0.5s;

      a {
        display: block;
        width: 100%;
        padding: 10px;
        box-shadow: 0px 1px 2px #153571;
      }

      .round {
        display: inline-block;
        width: auto;
        padding: 3px 20px;
        margin-top: 20px;
        margin-bottom: 50px;
      }
      .button-container {
        a {
          width: auto;
          display: inline-block;
          box-shadow: none;
        }

        .avatar-component {
          padding: 5px 10px;
          margin: 5px 10px;
        }
      }
    }

    &.visible {
      .burger-menu {
        transform: rotate(90deg);
      }
      .menu {
        left: 0;
      }
    }

    @media (min-width: 992px) {
      display: none;
    }
  }
  @media (min-width: 992px) {
    min-height: 500px;
    padding: 10px;

    &.small {
      min-height: inherit;
    }
  }

  .search-small {
    display: none;

    @media (min-width: 992px) {
      display: block;
      padding: 35px 0;
    }
  }

  .large-menu {
    display: none;
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
    }
    nav {
      text-align: right;
    }

    h1 {
      font-size: 24px;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    @media (min-width: 1200px) {
      h1 {
        font-size: 30px;
        margin-top: 70px;
      }
    }
    .search-component {
      margin-bottom: 20px;
    }
    .last-events-container {
      a {
        margin: 5px;
        margin-top: 10px;
      }
    }
    .categories-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(0, 50%);
    }
  }
}
