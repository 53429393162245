.category-menu-module {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: linear-gradient(270deg, #002b6e 6.11%, #124390 66.77%);
  padding: 25px 55px;

  .link-button {
    &.round {
      margin: 5px 6px;
    }
  }
}
