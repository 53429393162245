@import "settings.scss";

  .header-title {
    position: relative;
    display: flex;

    h2 {
      font-size: 18px;
      text-align: left;
      margin: 0 30px 25px;
      padding: 0 20px;
      display: inline-block;
      position: relative;
      background: $color-white;
    }
    
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: $color-lightgray;
      left: 0;
      top: 21%;
      position: absolute;
    }
  }