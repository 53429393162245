.out-render-bottom-module {
  display: flex;
  justify-content: space-between;

  .left-side {
    .remove-button {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .right-side {
  }
}
