.out-render-bottom-module {
  display: flex;
  justify-content: space-between;

  .left-side {
    .remove-button {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        font-weight: 800;
      }
    }
  }

  .right-side {
  }
}
