@import "settings.scss";
.cart-page {
  .gallery-module {
    position: relative;
    text-align: left;
    &:before {
      display: block;
      position: absolute;
      background: #fff;
      width: 100%;
      top: 10px;

      margin-left: -20px;
      content: "";
      border-top: 1px solid $color-lightgray;
    }
    h2 {
      color: $color-text;
      background: #fff;
      display: inline-block;
      font-size: 18px;
      position: relative;
      padding: 0 20px;
      margin-left: 20px;
    }
    .check-action-button {
      svg {
        color: #fff;

        border: 1px solid #000;
        border-radius: 3px;
        background: #000;
        font-size: 20px;
        padding: 0px 1px;
      }
    }
  }
}
