.user-panel-page {
  .user-panel-wrapper {
    @media (min-width: 576px) {
      margin-left: 105px;
      margin-right: 15px;
    }
    .user-panel-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;

      @media (min-width: 567px) {
        flex-direction: row;
      }

      h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        width: 20%;
      }

      .search-component {
        width: 70%;
      }
    }
    .user-panel-content {
      .subpage {
        margin: 10px;
        @media (min-width: 567px) {
          margin: 0;
        }
        .subpage-wrapper {
          .subpage-header {
            padding-top: 25px;
            padding-bottom: 25px;
            text-align: left;
            h1 {
              font-size: 22px;
              font-weight: 600;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
          .gallery-module {
            .gallery-wrapper {
              .gallery-header {
                position: absolute;
                top: 12%;
                margin: 0;
                right: 1%;

                @media (max-width: 567px) {
                  top: 18%;
                }
              }
              .gallery-content {
                margin: 0 0 60px;
              }
            }
          }
        }
      }
    }
  }
}
