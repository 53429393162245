.btn-group {
  border-radius: 50px !important;
  overflow: hidden;
  border: 1px solid #E1E1E1;
  box-shadow: 0px 4px 10px 0px #00000024;

    a {
      &.normal {
        color: black;
        background-color: white;
        margin: 0;
        padding: 8px 20px;
        border-right: 2px solid #E1E1E1;

        &:last-child() {
          border-right: none;
        }
      }
    }
}