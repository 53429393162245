@import "settings.scss";

.login-register-page {
  background: $primary-gradient;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .wrapper {
    display: flex;
    background-color: white;
    padding-bottom: 25px;
    .sponsor-btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      button {
        background: #d9d9d9;
        color: black;
        font-weight: 400;
        font-size: 16px;
        width: 30%;
        min-width: 70px;
        padding: 7px;
        margin-top: 5px;
      }
    }
    .right-section {
      width: 100%;
      .section-wrapper {
        .panel {
          padding: 25px 45px;

          .login-register-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 55px;

            h1 {
              font-size: 24px;
              font-weight: 700;
              line-height: 29px;
              letter-spacing: 0em;
              text-align: left;
              margin: 0;
            }

            a {
              color: black;
            }
          }

          .form-wrapper {
            p,
            span {
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: 0em;
              text-align: left;
              color: #656565;
            }

            .form {
              a {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                color: $color-blue;
                padding: 0;
                margin: 0;
              }
              .form-group {
                display: flex;
                flex-direction: column;
                padding-top: 20px;

                label {
                  text-align: left;
                  padding-bottom: 7px;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19px;
                  letter-spacing: 0em;
                }

                input {
                  padding: 5px 10px;
                  border: 1px solid #ced4da;
                }
              }

              .checkbox-wrapper {
                padding-top: 20px;
                display: flex;
                justify-content: space-between;

                .save-password {
                  display: flex;
                  align-items: center;

                  span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0em;
                    color: #656565;
                  }

                  .checkbox {
                    border: 1px solid #ced4da;
                    width: 16px;
                    height: 16px;
                    margin-right: 15px;
                  }
                }
              }

              .submit-wrapper {
                display: flex;
                justify-content: flex-end;
                padding-top: 25px;

                .submit-content {
                  width: 50%;
                  min-width: 100px;
                  text-align: left;

                  button {
                    width: 100%;
                    margin: 0 0 5px 0;
                    background: $color-blue;
                  }

                  a {
                    font-weight: 600;
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .side-graphic {
      display: none;
    }
  }
}

@media screen and (min-width: 1200px) {
  .login-register-page {
    height: 100vh;
    .wrapper {
      min-height: 700px;
      width: 1200px;
      padding-bottom: 15px;

      .right-section {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .side-graphic {
        width: 50%;
        display: block;
        position: relative;
        overflow: hidden;

        .elipse {
          position: absolute;
          z-index: 2;
          top: 200px;
          right: 150px;
        }
        .rectangle {
          position: absolute;
          z-index: 1;
          right: -120px;
          height: 800px;
        }
      }
    }
  }
}
